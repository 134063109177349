
import { Component, Vue, Watch, Model, Prop } from "vue-property-decorator";
import { isEqual } from "lodash";

const modelName = "inputValue";
const eventName = "change";

const REPLACE_REGEX = /[^\d\.]/g;

interface IRange<T> {
	min: T;
	max: T;
}

type INumberRange = IRange<number | null>;
type IStringRange = IRange<string | null>;

@Component<NumberRangeInput>({})
export default class NumberRangeInput extends Vue {
	@Model(eventName, {
		required: true,
	})
	readonly [modelName]!: INumberRange;

	@Prop({
		type: Number,
		default: 12
	})
	size!: number;

	@Prop({
		type: Boolean,
		default: false,
		required: false,
	})
	disabled!: boolean;

	value: IStringRange = this.changeToStringRange(this[modelName]);

	get changed() {
		return !isEqual(this.value, this[modelName]);
	}

	changeToStringRange(range: INumberRange) {
		return {
			min: this.onValuePartChanged(typeof range.min === "number" && range.min.toString() || null),
			max: this.onValuePartChanged(typeof range.max === "number" && range.max.toString() || null),
		};
	}

	changeToNumberRange(range: IStringRange) {
		let min = range.min && parseFloat(range.min.replace(REPLACE_REGEX, ""));
		let max = range.max && parseFloat(range.max.replace(REPLACE_REGEX, ""));

		return {
			min: typeof min === "number" && Number.isFinite(min) ? min : null,
			max: typeof max === "number" && Number.isFinite(max) ? max : null,
		};
	}

	@Watch(modelName)
	[`on${modelName}Change`](val: INumberRange) {
		this.value = this.changeToStringRange(val);
	}

	@Watch("value")
	onValueChange(value: IStringRange) {
		this.$emit(eventName, this.changeToNumberRange(value));
	}

	onValuePartChanged(value: string | null) {
		if (!value) {
			return null;
		}

		const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });

		value = value && value.replace(REPLACE_REGEX, "");

		let valueNumber: number | null | "" = value && parseFloat(value);
		valueNumber = typeof valueNumber === "number" && Number.isFinite(valueNumber) ? valueNumber : null;
		value = typeof valueNumber === "number" && formatter.format(valueNumber) || null;

		return value;
	}

	priceRangePartChange() {
		const {value} = this;

		if (!value) {
			return;
		}

		let {min, max} = value;
		min = min && min.replace(REPLACE_REGEX, "");
		max = max && max.replace(REPLACE_REGEX, "");

		let minNumber = min && parseFloat(min);
		let maxNumber = max && parseFloat(max);

		minNumber = typeof minNumber === "number" && Number.isFinite(minNumber) ? minNumber : null;
		maxNumber = typeof maxNumber === "number" && Number.isFinite(maxNumber) ? maxNumber : null;

		if (minNumber !== null && maxNumber !== null && minNumber > maxNumber) {
			const _minNumber = minNumber;
			const _maxNumber = maxNumber;

			minNumber = _maxNumber;
			maxNumber = _minNumber;
		}

		value.min = typeof minNumber === "number" && minNumber.toString() || null;
		value.max = typeof maxNumber === "number" && maxNumber.toString() || null;
	}

	@Watch("value.min")
	onValueMinChanged(min: string | null, oldMin: string | null) {
		this.value.min = this.onValuePartChanged(min);

		if (this.value.min !== oldMin) {
			this.value = {
				min: this.value.min,
				max: this.value.max,
			};
		}
	}

	@Watch("value.max")
	onValueMaxChanged(max: string | null, oldMax: string | null) {
		this.value.max = this.onValuePartChanged(max);

		if (this.value.max !== oldMax) {
			this.value = {
				min: this.value.min,
				max: this.value.max,
			};
		}
	}
}
